export default (context, inject) => {
  const returnCompleteUrl = (string) => {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return addGraphqlUrl(string);
    }

    if (url.protocol === 'http:' || url.protocol === 'https:') {
      return string;
    } else {
      return addGraphqlUrl(string);
    }
  }

  const addGraphqlUrl = (string) => {
    return context.$config.baseUrl + string;
  }

  const prependUrls = (string) => {
    return string.replace(
      /(\/uploads\/[a-zA-Z0-9-._?&\[\]]*)/g,
      (match) => {
        return `${returnCompleteUrl(match)}`;
      }
    );
  }

  const prefixHttps = (string) => {
    var https = 'https://';
    var http = 'http://';
    return string.substr(0, https.length) !== https && string.substr(0, http.length) !== http ? https + string : string
  }

  inject('returnCompleteUrl', returnCompleteUrl);
  context.$returnCompleteUrl = returnCompleteUrl;

  inject('prependUrls', prependUrls);
  context.$prependUrls = prependUrls;

  inject('prefixHttps', prefixHttps);
  context.$prefixHttps = prefixHttps;
}