export default {
  setPageScroll({ commit }, pageScroll) {
    commit("SET_PAGE_SCROLL", pageScroll);
  },
  setNewsLimit({ commit }, newsLimit) {
    commit("SET_NEWS_LIMIT", newsLimit);
  },
  setTranslateUrl({ commit }, value) {
    commit("SET_TRANSLATE_URL", value);
  },
};
