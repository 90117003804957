import { extend, setInteractionMode  } from "vee-validate"
import { required, regex } from "vee-validate/dist/rules"

extend("required", {
  ...required,
  message: "Ce champs est requis"
})

extend("regex", regex)

setInteractionMode('passive')

