
export default {
  transition: 'page',
  data() {
    return {
      menuVisible: false,
    };
  },
  watch: {
    '$route.path': function() {
      this.menuVisible = false;
    },
    menuVisible() {
      if(!process.server){
        document.body.classList.toggle('menuOpen')
      }
    }
  },
  methods:{  
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    }
  }
}
