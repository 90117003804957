
export default {
  props: {
    iconVisible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleMenuMobile() {
      this.$emit("toggleMenuMobile");
    },
  },
};
