import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0e433b93 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _ebefb5ec = () => interopDefault(import('../pages/Articles/index.vue' /* webpackChunkName: "pages/Articles/index" */))
const _38b0dfdf = () => interopDefault(import('../pages/automotive.vue' /* webpackChunkName: "pages/automotive" */))
const _47aff8a7 = () => interopDefault(import('../pages/privacy-notice.vue' /* webpackChunkName: "pages/privacy-notice" */))
const _58dee93b = () => interopDefault(import('../pages/careers.vue' /* webpackChunkName: "pages/careers" */))
const _1d238929 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _128acd56 = () => interopDefault(import('../pages/legal-notice.vue' /* webpackChunkName: "pages/legal-notice" */))
const _5f9301d6 = () => interopDefault(import('../pages/partners.vue' /* webpackChunkName: "pages/partners" */))
const _07f8a58a = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _d95eab06 = () => interopDefault(import('../pages/soutien.vue' /* webpackChunkName: "pages/soutien" */))
const _d06b05e0 = () => interopDefault(import('../pages/media-kits.vue' /* webpackChunkName: "pages/media-kits" */))
const _40b45612 = () => interopDefault(import('../pages/heavy-vehicles.vue' /* webpackChunkName: "pages/heavy-vehicles" */))
const _56751ea4 = () => interopDefault(import('../pages/contact/confirmation.vue' /* webpackChunkName: "pages/contact/confirmation" */))
const _229fce96 = () => interopDefault(import('../pages/napa-altrom.vue' /* webpackChunkName: "pages/napa-altrom" */))
const _ef5cf87c = () => interopDefault(import('../pages/Articles/_slug.vue' /* webpackChunkName: "pages/Articles/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-propos",
    component: _0e433b93,
    name: "about___fr-ca"
  }, {
    path: "/actualites",
    component: _ebefb5ec,
    name: "Articles___fr-ca"
  }, {
    path: "/automobiles",
    component: _38b0dfdf,
    name: "automotive___fr-ca"
  }, {
    path: "/avis-confidentialite",
    component: _47aff8a7,
    name: "privacy-notice___fr-ca"
  }, {
    path: "/carrieres",
    component: _58dee93b,
    name: "careers___fr-ca"
  }, {
    path: "/contact",
    component: _1d238929,
    name: "contact___fr-ca"
  }, {
    path: "/en-ca",
    component: _2dfb1658,
    name: "index___en-ca"
  }, {
    path: "/mentions-legales",
    component: _128acd56,
    name: "legal-notice___fr-ca"
  }, {
    path: "/partenaires",
    component: _5f9301d6,
    name: "partners___fr-ca"
  }, {
    path: "/plan-site",
    component: _07f8a58a,
    name: "sitemap___fr-ca"
  }, {
    path: "/soutien",
    component: _d95eab06,
    name: "soutien___fr-ca"
  }, {
    path: "/trousses-medias",
    component: _d06b05e0,
    name: "media-kits___fr-ca"
  }, {
    path: "/vehicules-lourds",
    component: _40b45612,
    name: "heavy-vehicles___fr-ca"
  }, {
    path: "/contact/confirmation",
    component: _56751ea4,
    name: "contact-confirmation___fr-ca"
  }, {
    path: "/en-ca/about-us",
    component: _0e433b93,
    name: "about___en-ca"
  }, {
    path: "/en-ca/automotives",
    component: _38b0dfdf,
    name: "automotive___en-ca"
  }, {
    path: "/en-ca/careers",
    component: _58dee93b,
    name: "careers___en-ca"
  }, {
    path: "/en-ca/contact",
    component: _1d238929,
    name: "contact___en-ca"
  }, {
    path: "/en-ca/heavy-vehicles",
    component: _40b45612,
    name: "heavy-vehicles___en-ca"
  }, {
    path: "/en-ca/legal-notices",
    component: _128acd56,
    name: "legal-notice___en-ca"
  }, {
    path: "/en-ca/media-kits",
    component: _d06b05e0,
    name: "media-kits___en-ca"
  }, {
    path: "/en-ca/news",
    component: _ebefb5ec,
    name: "Articles___en-ca"
  }, {
    path: "/en-ca/partners",
    component: _5f9301d6,
    name: "partners___en-ca"
  }, {
    path: "/en-ca/privacy-notice",
    component: _47aff8a7,
    name: "privacy-notice___en-ca"
  }, {
    path: "/en-ca/sitemap",
    component: _07f8a58a,
    name: "sitemap___en-ca"
  }, {
    path: "/en-ca/support",
    component: _d95eab06,
    name: "soutien___en-ca"
  }, {
    path: "/soutien/napaaltrom",
    component: _229fce96,
    name: "napa-altrom___fr-ca"
  }, {
    path: "/en-ca/contact/confirmation",
    component: _56751ea4,
    name: "contact-confirmation___en-ca"
  }, {
    path: "/en-ca/support/napaaltrom",
    component: _229fce96,
    name: "napa-altrom___en-ca"
  }, {
    path: "/en-ca/news/:slug",
    component: _ef5cf87c,
    name: "Articles-slug___en-ca"
  }, {
    path: "/actualites/:slug",
    component: _ef5cf87c,
    name: "Articles-slug___fr-ca"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___fr-ca"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
