
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      translateUrl: (state) => state.translateUrl,
    }),
  },
  methods: {
    switchLang() {
      if (
        this.translateUrl &&
        this.$route.path != this.$t("news.urlNews") &&
        this.$route.path.indexOf(this.$t("news.urlNews")) > -1
      ) {
        return this.translateUrl;
      } else if (this.$route.path.indexOf(this.$t("news.urlNews")) == 0) {
        return this.$t("news.urlNewsTranslate");
      } else {
        return this.switchLocalePath(this.$t("header.LangSwitch.code"));
      }
    },
  },
};
