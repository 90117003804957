
  export default {
    props: ['error'],
    layout: 'empty',
    name:'error',
    head() {
      return {
        title: this.$t('error.metaTitle'),
        meta: [
          {
            hid: "description",
            name: "description",
            content: this.$t('error.metaDescription'),
          },
          {
            hid: 'og:description',
            name: 'og:description',
            content: this.$t('error.metaDescription'),
          },
        ],
      };
    }
  }
