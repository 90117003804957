export default {
  //Fonction qui assigne la valeur a login
  SET_PAGE_SCROLL(state, value) {
    state.pageScroll = value;
  },
  SET_PAGE_ANIMATION(state, value) {
    state.pageAnimation = "";
    state.pageAnimation = value;
  },
  SET_NEWS_LIMIT(state, value) {
    state.newsLimit = value;
  },
  SET_TRANSLATE_URL(state, value) {
    state.translateUrl = value;
  },
};
