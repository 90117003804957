
import {mapActions, mapState, mapMutations} from "vuex"

export default {
  head(){
    return{
      htmlAttrs: {
        Id:'html',
      },
    }
  },
  transition: 'page',
  data() {
    return {
      menuVisible: false,
      pageLoaded:false,
      renderValue: 0,
      lastRoute: '',
      lastLastRoute: ''
    };
  },
  mounted(){
    if(!process.server){
      this.setPageScroll(document.documentElement.scrollHeight);
      window.addEventListener('scroll', this.handleScroll);
      window.history.scrollRestoration = 'auto';
      this.lastRoute = this.$route.path
    }
    
    this.pageLoaded = true
    this.renderValue += 1
  },
  watch: {
    '$route.path': function(value, oldRoute) {
      this.removeSmoothScroll();
      if (this.lastRoute === value) {
        setTimeout(() => {
          window.scrollTo(0, 0)
          this.setPageScroll(document.documentElement.scrollHeight);
        },1100)
      }
      this.lastRoute = oldRoute
      this.menuVisible = false;

      this.setPageAnimation('')
      
    },
    menuVisible() {
      if(!process.server){
        document.body.classList.toggle('menuOpen')
      }
    }
  },
  methods:{  
    ...mapActions(['setPageScroll']),
    ...mapMutations({
      setPageAnimation:'SET_PAGE_ANIMATION'
    }),
    removeSmoothScroll(){
      const htmlTag = document.querySelector("html")

      if(this.$route.hash == null || this.$route.hash == ''){
        if(htmlTag && htmlTag.classList){
          htmlTag.classList.add('noSmoothScroll');
          
          setTimeout(function() {
            htmlTag.classList.remove('noSmoothScroll');
          }, 2500)
        }
      }
    },
    handleScroll(event){
      if(event && event.target && event.target.scrollingElement && event.target.scrollingElement.scrollTop){
        const windowScrollTop = event.target.scrollingElement.scrollTop;
        this.setPageScroll(windowScrollTop);
      }
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    }
  },
  computed: {
    ...mapState({
      pageAnimation:(state) => state.pageAnimation
    }),
    isOnHome(){
      return this.localePath('index') === this.$route.path;
    },
    isOnNews(){
      return this.localePath('Articles') === this.$route.path;
    },
    isOnArticle(){
      return this.$route.path.includes(this.localePath('Articles')) && this.$route.params.slug != null;
    }
  }
}
